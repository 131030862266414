const CARD_WIDTH = 224;
const GAP_SIZE = 28;

export const calculateContentWidth = (width, sidebarMinWidth = 0) => {
  const availableWidth = width - sidebarMinWidth;
  const cardsPerRow = Math.floor(availableWidth / (CARD_WIDTH + GAP_SIZE));
  const contentWidth = (cardsPerRow * CARD_WIDTH) + ((cardsPerRow - 1) * GAP_SIZE);
  if (availableWidth - contentWidth >= CARD_WIDTH + GAP_SIZE) {
    return contentWidth + CARD_WIDTH + GAP_SIZE;
  }
  return contentWidth;
}
