import React, { useEffect, useMemo, useState } from 'react'
import { ThemeTileContainer } from '../theme/ThemeTile';
import { H3, Button, colors, MagicIcon, SkubotSpinner } from '@commonsku/styles';
import { InstantSearch, Configure } from 'react-instantsearch';
import aa from 'search-insights';
import CustomHits from './CustomHits';
import ProductSuggestionSearch from './ProductSuggestionSearch';
import { connect } from 'react-redux';
import { createAddItem, createDeleteItem } from '../../actions';
import { createLoadDivision, createLoadSupplier } from '../../actions/supplier';
import { createFetchProductDetails } from '../../actions/product';
import { getConnectedPlusDivisionOptions } from '../../selectors/dropdowns';
import { isEmpty } from 'lodash';
import { searchClient, indexName } from './Algoliasearch';
import { useSize } from '../../hooks/useSize';
import { calculateContentWidth } from './helpers';
const itemsPerPage = 10;
const MAX_SEARCH_TERMS = 3;

const SuggestionView = ({ themes, order, onSelectTheme, loading, onAddItem, onDeleteItem, divisionsArray, setIsSuggestSearch, setSuggestion, suggestions }) => {
  const [ref, size] = useSize();
  const contentWidth = calculateContentWidth(size.clientWidth ?? 0);
  const renderProductSuggestionSection = (searchTerm, index, title) => {
    return (
      <div key={`suggestion-${index}`} style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 8, width: contentWidth }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 26 }}>
            <MagicIcon color={colors.neutrals.darkest} />
            <H3 style={{ fontWeight: 700, fontSize: 20, lineHeight: '32px', marginBottom: 0, color: colors.neutrals.darkest }}>{title}</H3>
          </div>
          <Button
            variantBg='transparent'
            variantBorderColor='transparent'
            variantColor={colors.primary1.main}
            variantHoverBorderColor={colors.primary1['20']}
            variantHoverColor={colors.primary1.main}
            variantHoverBg={colors.primary1['20']}
            size="medium" variant='custom' onClick={() => {
              setSuggestion(searchTerm);
              setIsSuggestSearch(true);
            }}>View More</Button>
        </div>
        <InstantSearch searchClient={searchClient} indexName={indexName} future={{ preserveSharedStateOnUnmount: true }} insights={true} >
          <CustomHits loading={loading} order={order} onAddItem={onAddItem} onDeleteItem={onDeleteItem} divisions={divisionsArray} />
          <Configure userToken={order.tenant_id} hitsPerPage={itemsPerPage} clickAnalytics={true} query={searchTerm} />
        </InstantSearch >
      </div>
    );
  };

  const renderSuggestionSections = () => {
    if (!suggestions.searchTerms || !suggestions.searchTerms.length) {
      return null;
    }

    const termsToShow = suggestions.searchTerms.slice(0, MAX_SEARCH_TERMS);
    return termsToShow.map((term, index) => renderProductSuggestionSection(term, index, `"Products for ${suggestions.theme} - ${term}"`));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 32, overflowY: 'auto', marginTop: 20 }} ref={ref}>
      {renderSuggestionSections()}
      {!isEmpty(themes) && <ThemeTileContainer themes={themes} order={order} title={'Suggestions'} onClick={onSelectTheme} />}
    </div>
  );
};

export function SuggestionContainer({ themes, order, onSelectTheme, isSuggestSearch, loading, onAddItem, onDeleteItem, divisions, loadDivisions, onClear, setIsSuggestSearch, suggestions, recommendationsLoading }) {
  const userToken = order.tenant_id;
  const [selectedSuggestion, setSelectedSuggestion] = useState('');

  useEffect(() => {
    loadDivisions('connected-plus');
    aa('setUserToken', userToken);
  }, []);

  const divisionsArray = useMemo(() => {
    return divisions.reduce((acc, division) => {
      acc[division.key] = division.value;
      return acc;
    }, {});
  }, [divisions]);

  if (isSuggestSearch) {
    return (
      <ProductSuggestionSearch
        order={order}
        loading={loading}
        onAddItem={onAddItem}
        onDeleteItem={onDeleteItem}
        divisions={divisionsArray}
        onClear={onClear}
        suggestion={selectedSuggestion}
        setSuggestion={setSelectedSuggestion}
      />
    );
  }

  if (recommendationsLoading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 32, marginTop: 20, justifyContent: 'center', alignItems: 'center' }}>
        <SkubotSpinner />
        <H3 style={{ fontWeight: 700, fontSize: 20, lineHeight: '32px', marginBottom: 0, color: colors.neutrals.darkest }}>Generating recommendations…</H3>
      </div>
    );
  }

  return (
    <SuggestionView
      themes={themes}
      order={order}
      onSelectTheme={onSelectTheme}
      loading={loading}
      onAddItem={onAddItem}
      onDeleteItem={onDeleteItem}
      divisionsArray={divisionsArray}
      setIsSuggestSearch={setIsSuggestSearch}
      suggestions={suggestions}
      setSuggestion={setSelectedSuggestion}
    />
  );
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.display.loading.add_item,
  divisions: getConnectedPlusDivisionOptions(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddItem: (item, kwargs = { fetch_update: true }) => {
    const { order } = ownProps;
    const { order_type, order_id } = order;
    const parent_id = item.ext_product_id || item.objectID;
    if ('OPPORTUNITY' === order_type || !order_type) {
      return dispatch(createFetchProductDetails(parent_id, item.copied_from, 'MESSAGE'));
    } else if ('PRESENTATION' === order_type || 'COLLECTION' === order_type || 'SHOP' === order_type) {
      return dispatch(createAddItem(
        order_id, 'OPTION', parent_id, item.copied_from, null, null, null,
        item.currency_id,
        null, item.product_supplier_code, item.division_id, 'reference', 'sage_connect' === item.copied_from, { ...kwargs }
      ));
    } else {
      return dispatch(createAddItem(
        order_id, 'PRODUCT', parent_id, item.copied_from, null, null, null,
        item.currency_id,
        null, item.product_supplier_code, item.division_id, 'all', 'sage_connect' === item.copied_from, { ...kwargs }
      ));
    }
  },
  onDeleteItem: item_id => {
    return dispatch(createDeleteItem(item_id));
  },
  loadDivisions: supplier_type => {
    if ('collection' === supplier_type) {
      dispatch(createLoadSupplier(supplier_type));
    } else {
      supplier_type = 'sage-connect' === supplier_type ? 'sage' : supplier_type;
      dispatch(createLoadDivision('commonsku' === supplier_type ? 'supplier-direct' : supplier_type));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionContainer);